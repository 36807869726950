import { NgFor, NgIf } from "@angular/common";
import { Directive, HostListener, Input } from "@angular/core";
import { EventService } from "@services/event.service";
import { StorageService } from "@services/storage.service";
import { ModeDevice } from "src/app/constants/application/application-constants";
import { MetaData } from "src/app/interfaces/item-meta-interface";

@Directive({
    selector: "[click-event]",
    standalone: true,
    providers: [NgIf, NgFor]
})
export class ClickEventDirective {
  @Input() event: string;
  @Input() eventType: string;
  @Input() targetEntityId: string;
  @Input() targetEntityType: string;
  @Input() matched_status: string;
  @Input() item_id: string;
  @Input() item_value: string;
  @Input() item_url: string;
  @Input() item_name: string;
  @Input() page_type: string;
  @Input() page_type_value: string;
  @Input() page_value: string;
  @Input() feature_type: string;
  @Input() feature_value: string;
  @Input() feature_position: string | number;
  @Input() widget_id: string;
  @Input() x_id: string;
  @Input() stock_status: string;
  @Input() category: string;
  @Input() mrp: string;
  @Input() our_price: string;
  @Input() offer_price: string;
  @Input() page_number: string;
  @Input() listed_by: string;
  @Input() item_position: string | number;
  @Input() item_page: string;
  @Input() item_type: string;
  @Input() item_type_value: string;
  @Input() feature_type_value: string;
  @Input() event_view_type: string;
  @Input() eventData: MetaData = {} as MetaData;
  // for Search
  @Input() search_term: string;
  @Input() search_type: string;
  @Input() intent_type: string;
  @Input() search_place: string;
  @Input() search_results: string;
  @Input() target_type: string;
  @Input() target_type_value: string;
  // for click stream
  @Input() click_module: string;
  @Input() click_module_id: string;
  @Input() click_module_name: string;
  @Input() icon_select_name: string;
  @Input() icon_select_value: string;
  @Input() position: string | number;
  @Input() section: string;
  @Input() tempPageType: any;
  @Input() tempPageTypeValue: any;
  @Input() action_type: any;
  @Input() reference: any;
  // interaction
  @Input() action: any;
  @Input() type: any;
  @Input() subtype: any;
  @Input() value: any;
  // android
  @Input() noEvent: any;
  // third party events
  @Input() third_party_event_params: any;
  @Input() cart_id: string;

  @HostListener("click", ["$event"])
  getClicks() {
    let data = {};
    let eventDataCopy = {};
    if (this.eventData && typeof this.eventData == "object") eventDataCopy = { ...this.eventData };
    let dataKeys = this.getEventDataKeys();
    for (let prop in dataKeys) {
      const { key, value } = dataKeys[prop];
      this.setEventData(data, prop, key && eventDataCopy[key], value);
    }

    let mode_device = this.storageService.getCookie("mode_device");

    if ([ModeDevice.mobile, ModeDevice.desktop].includes(mode_device)) this.eventService.clickEvent(data);
    return data;
  }
  constructor(private eventService: EventService, private storageService: StorageService) { }

  setEventData(eventData, assignedKey, availableVal, v2) {
    if (availableVal || availableVal == 0) eventData[assignedKey] = availableVal;
    else eventData[assignedKey] = v2;
  }
  getEventDataKeys() {
    // Here key points to eventData[key] and value points to alternate value when data not available with eventData[key]
    return {
      'event': { key: 'eventName', value: this.event },
      'target_entity_id': { key: 'target_entity_id', value: this.targetEntityId },
      'target_entity_type': { key: 'target_entity_type', value: this.targetEntityType },
      'matched_status': { key: 'matched_status', value: this.matched_status },
      'item_id': { key: 'item_id', value: this.item_id },
      'item_value': { key: 'item_value', value: this.item_value },
      'item_url': { key: 'item_url', value: this.item_url },
      'item_name': { key: 'item_name', value: this.item_name },
      'page_type': { key: 'page_type', value: this.page_type },
      'page_type_value': { key: 'page_type_value', value: this.page_type_value },
      'page_value': { key: 'page_value', value: this.page_value },
      'feature_type': { key: 'feature_type', value: this.feature_type },
      'feature_value': { key: 'feature_value', value: this.feature_value },
      'feature_position': { key: 'feature_position', value: this.feature_position + '' },
      'event_view_type': { key: 'event_view_type', value: this.event_view_type },
      'widget_id': { key: 'widget_id', value: this.widget_id },
      'x_id': { key: 'x_id', value: this.x_id },
      'stock_status': { key: 'stock_status', value: this.stock_status },
      'mrp': { key: 'mrp', value: this.mrp },
      'category': { key: 'category', value: this.category },
      'our_price': { key: 'our_price', value: this.our_price },
      'offer_price': { key: 'offer_price', value: this.offer_price },
      'page_number': { key: 'page_number', value: this.page_number },
      'listed_by': { key: 'listed_by', value: this.listed_by },
      'item_position': { key: 'item_position', value: this.item_position + '' },
      'item_page': { key: 'item_page', value: this.item_page },
      'item_type': { key: 'item_type', value: this.item_type },
      'item_type_value': { key: 'item_type_value', value: this.item_type_value },
      'feature_type_value': { key: 'feature_type_value', value: this.feature_type_value },
      'tempPageType': { key: 'tempPageType', value: this.tempPageType },
      'tempPageTypeValue': { key: 'tempPageTypeValue', value: this.tempPageTypeValue },
      'cart_id': { key: "cart_id", value: this.cart_id },
      //auth_action
      'action_type': { key: 'action_type', value: this.action_type },
      'reference': { key: 'reference', value: this.reference },
      // for Search
      'search_term': { key: 'search_term', value: this.search_term },
      'search_type': { key: 'search_type', value: this.search_type },
      'intent_type': { key: 'intent_type', value: this.intent_type },
      'search_place': { key: 'search_place', value: this.search_place },
      'search_results': { key: 'search_results', value: this.search_results },
      'target_type': { key: 'target_type', value: this.target_type },
      'target_type_value': { key: 'target_type_value', value: this.target_type_value },
      // for click stream
      'click_module': { key: 'click_module', value: this.click_module },
      'click_module_id': { key: 'click_module_id', value: this.click_module_id },
      'click_module_name': { key: 'click_module_name', value: this.click_module_name },
      'icon_select_name': { key: 'icon_select_name', value: this.icon_select_name },
      'icon_select_value': { key: 'icon_select_value', value: this.icon_select_value },
      'position': { key: 'position', value: this.position + '' },
      'section': { key: 'section', value: this.section },
      // interaction
      'action': { key: 'action', value: this.action },
      'type': { key: 'type', value: this.type },
      'subtype': { key: 'subtype', value: this.subtype },
      'value': { key: 'value', value: this.value },
      'sclid': { key: 'sclid', value: undefined },
      'uclid': { key: 'uclid', value: undefined },
      'third_party_event_params':
        { key: null, value: this.third_party_event_params && JSON.stringify(this.third_party_event_params) },
      'noEvent': { key: null, value: this.noEvent ? this.noEvent : false },
    }
  }

}
